<template>
    <b-form-group
                :label="label"
                label-for="select-empresa"
                :label-class="labelClass"
            >
              <v-select
                  id="select-empresa"
                  v-model="value.empresa"
                  variant="custom"
                  item-text="descricao"
                  item-value="id_empresa"
                  :label="empresaFilter"
                  placeholder="Selecione uma empresa"
                  :disabled="disableAll"
                  :values="options"
                  :options="options"
                  :loading="loading"
                  @input="value.endereco = null"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
                <template #option="item">
                  <strong>{{item.documento_formatado}}</strong><br>
                  <span v-if="empresaFilter != 'doc_filter' && item[empresaFilter]">{{ item[empresaFilter] }}</span>
                  <span v-else>{{ item.nome_empresa }}</span>
                </template>
                <template #selected-option="item">
                  <span>
                    {{ empresaFilter != 'doc_filter' ? item[empresaFilter] : item.documento_formatado }}
                  </span>
                </template>
              </v-select>

              <b-form-radio-group
                id="radio-group-empresa"
                v-model="empresaFilter"
                name="radio-sub-component"
                class="mt-10px radio-empresa"
                size="sm"
              >
                <span class="mr-1">Filtrar por:</span>
                <b-form-radio value="doc_filter">CNPJ/CPF</b-form-radio>
                <b-form-radio value="razao_social">Razão Social</b-form-radio>
                <b-form-radio value="nome_empresa">Nome Fantasia</b-form-radio>
              </b-form-radio-group>

              <small
                  v-if="showError"
                  class="text-danger"
              >
                {{ errorMessage }}
              </small>
            </b-form-group>
  </template>
  
  <script>
  import {
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio
} from 'bootstrap-vue';
import vSelect from 'vue-select';

  export default {
    components: {
        BFormGroup,
        BFormInput,
        BFormRadioGroup,
        BFormRadio,
        vSelect
    },

    props: {
      empresaSelect: {
        type: Array,
        required: true,
        default: () => []
      },
      value: {
        type: Object,
        required: true
      },
      loading: {
        type: Boolean,
        default: true
      },
      disableAll: {
        type: Boolean,
        default: true
      },
      labelClass: {
        type: String,
        default: 'font_size_label'
      },
      label: {
        type: String,
        default: 'Empresa'
      },
      showError: {
        type: Boolean,
        default: false
      },
      errorMessage: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        empresaFilter: 'doc_filter'
      }
    },
    computed: {
      options() {
        let options = []
        this.empresaSelect.map(item => {
          options.push({
            ...item,
            doc_filter: item.documento_formatado +' '+item.documento
          })

        })

        return options
      }
    }
  }
  </script>
